import { useFormik } from "formik";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import { Checkbox, FormControlLabel } from "@material-ui/core";
// @ts-ignore
import { useNeonCheckboxStyles } from "@mui-treasury/styles/checkbox/neon";

const digestMessage = async (message: any) => {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // convert bytes to hex string
  return hashHex;
};

const getRandomInt = (max: number, min: number = 1): number => {
  return Math.floor(Math.random() * (max - min)) + min;
};

const getNumbers = (hash: string): number[] => {
  const ret = hash
    .split("")
    .filter((chr) => {
      const n = +chr;
      const s = n.toString(16).trim();
      return s === chr ? true : false;
    })
    .map((chr) => +chr);
  return [7, ...ret];
};

const getLastNumber = (hash: string): number => {
  const numbers = getNumbers(hash);
  return numbers[numbers.length - 1];
};

const getNumbersLength = (hash: string): number => {
  const l = getNumbers(hash).length;
  const lStr = l.toString();
  // @ts-ignore this is Correct !!!
  return parseInt(lStr.slice(-1));
};

const getLastInSum = (hash: string): number => {
  const numbers = getNumbers(hash);
  let sum = 10;
  numbers.forEach((n) => {
    sum += n;
  });
  const sumStr = sum.toString();
  // @ts-ignore this is Correct !!!
  //console.log("AAA", sumStr.split().pop());

  return parseInt(sumStr.slice(-1));
};

const getPasswordLength = (
  hash: string,
  minLength: number,
  maxLength: number
): number => {
  const k = (maxLength - minLength) / 10;
  const plusLength = getLastNumber(hash) * k;
  return minLength + Math.floor(plusLength);
};

const createPwd = async (
  name: string,
  email: string,
  password: string,
  containsNumber = true,
  containsChar = "_",
  minlength = 10,
  maxLength = 16
) => {
  const hash = await digestMessage(name + email + password);
  console.log(getPasswordLength(hash, minlength, maxLength));

  const pwdLength = getPasswordLength(hash, minlength, maxLength) - 3; // 2 spec chars
  const pwd = hash.substring(0, pwdLength);
  // console.log(pwd);

  // console.log("lastinsum", getLastInSum(hash));

  // const charPosition = Math.floor((getLastInSum(hash) / 10) * pwd.length);

  // console.log(charPosition);

  // const pwdWithChar =
  //   pwd.substring(0, charPosition) +
  //   containsChar +
  //   getNumbersLength(hash) +
  //   pwd.substring(charPosition);
  return "A1_" + pwd;
};

const Val = ({ children, label }: any) => {
  return (
    <li>
      <span className="label">{label}</span>
      <span>{children}</span>
      <span className="btn-copy">Copy</span>
    </li>
  );
};

const PwdForm = () => {
  const neonStyles = useNeonCheckboxStyles();

  const [renderValues, setRenderValues] = useState({
    username: "",
    password: "",
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      username: "",
      mustNotContains: "",
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      let name = values.name;
      if (name.startsWith("http://") || name.startsWith("https://")) {
        if (name.startsWith("http://")) {
          name = name.substring(7);
        } else if (name.startsWith("https://")) {
          name = name.substring(8);
        }
        name = name.split("?")[0];
        if (name.endsWith("/")) {
          name = name.substring(0, name.length - 1);
        }
      }
      createPwd(name, values.email, values.password).then((res) => {
        //console.log(res);
        setRenderValues({
          password: res,
          username: values.email,
        });
        //setPwd(res);
      });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Name (eg.: 'www.woice.cz', 'windows10', 'myphone'"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          fullWidth
          id="email"
          name="email"
          label="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="text"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <div className="advance" style={{ display: "none" }}>
          <TextField
            fullWidth
            id="mustNotContains"
            name="mustNotContains"
            label="Must not contains chars"
            type="text"
            value={formik.values.mustNotContains}
            onChange={formik.handleChange}
            error={
              formik.touched.mustNotContains &&
              Boolean(formik.errors.mustNotContains)
            }
            helperText={
              formik.touched.mustNotContains && formik.errors.mustNotContains
            }
          />
        </div>
        <br />
        <br />
        <br />
        <Button color="primary" variant="contained" fullWidth type="submit">
          Submit
        </Button>
      </form>
      <div
        style={{
          marginTop: 40,
          border: "1px solid black",
          background: "#80808057",
          minHeight: 200,
          textAlign: "left",
          padding: 20,
        }}
      >
        <ul className="result">
          <Val label="username">{renderValues.username}</Val>
          <Val label="password">{renderValues.password}</Val>
        </ul>
      </div>
    </div>
  );
};

//https://10minutemail.com/ -> address create 10 min email

export { PwdForm };
