import React from "react";
import "./App.css";
import { PwdForm } from "./PwdForm";

function App() {
  return (
    <div className="App">
      <PwdForm />
      {/* <ResultPrew /> */}
    </div>
  );
}

export default App;
